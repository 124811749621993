import { AxiosPromise } from 'axios'
import { api, apiWithoutHandling } from '@src/api'
import { getCommentsAPI } from '@src/api/comments'
import { API } from '@src/constants/api'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import {
  OnboardingInterface,
  OnboardingTaskInterface,
  OnboardingTemplateInterface,
} from '@src/interfaces/onboardingV2'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getOnboardingList = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<OnboardingInterface>> =>
  api.get(
    `${API.ONBOARDING}/employees`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const createOnboarding = (employeeId: number, templateId?: number) =>
  apiWithoutHandling.post<OnboardingInterface>(
    `${API.ONBOARDING}/employees`,
    {
      employee: {
        id: employeeId,
      },
      ...(templateId && { onboarding_template: { id: templateId } }),
    },
    undefined,
    'v2',
  )

export const useOnboardingData = (onboardingId?: string) => {
  return useFetch<OnboardingInterface>(
    onboardingId ? `${API.ONBOARDING}/employees/${onboardingId}` : null,
    'v2',
  )
}

export const cancelOnboarding = (onboardingId: number, cancel_reason?: string) =>
  apiWithoutHandling.post<OnboardingInterface>(
    `${API.ONBOARDING}/employees/${onboardingId}/cancel`,
    { cancel_reason },
    undefined,
    'v2',
  )

export const completeOnboarding = (onboardingId: number) =>
  apiWithoutHandling.post<OnboardingInterface>(
    `${API.ONBOARDING}/employees/${onboardingId}/complete`,
    undefined,
    undefined,
    'v2',
  )

export const startOnboarding = (onboardingId: number) =>
  apiWithoutHandling.post<OnboardingInterface>(
    `${API.ONBOARDING}/employees/${onboardingId}/start`,
    undefined,
    undefined,
    'v2',
  )

export const transferOnboarding = (
  onboardingId: number,
  hr_manager: EmployeeOptionInterface,
) =>
  apiWithoutHandling.post<OnboardingInterface>(
    `${API.ONBOARDING}/employees/${onboardingId}/transfer`,
    { hr_manager },
    undefined,
    'v2',
  )

export const useDocumentsUpload = (onboardingId?: string) => {
  return useFetch<GetRequestInterface<OnboardingTemplateInterface>>(
    onboardingId
      ? `${API.ONBOARDING}/employees/${onboardingId}/documentUploadRequests`
      : null,
    'v2',
  )
}

export const addDocumentUpload = (onboardingId: string, id: number) =>
  apiWithoutHandling.post(
    `${API.ONBOARDING}/employees/${onboardingId}/documentUploadRequests`,
    { document_template: { id } },
    undefined,
    'v2',
  )

export const deleteUploadRequest = (onboardingId: string, id: number) =>
  apiWithoutHandling.delete(
    `${API.ONBOARDING}/employees/${onboardingId}/documentUploadRequests/${id}`,
    undefined,
    'v2',
  )

export const useDocumentsSignature = (onboardingId?: string) => {
  return useFetch<GetRequestInterface<OnboardingTemplateInterface>>(
    onboardingId
      ? `${API.ONBOARDING}/employees/${onboardingId}/documentSignatureRequests`
      : null,
    'v2',
  )
}

export const addDocumentSignature = (onboardingId: string, id: number) =>
  apiWithoutHandling.post(
    `${API.ONBOARDING}/employees/${onboardingId}/documentSignatureRequests`,
    { document_template: { id } },
    undefined,
    'v2',
  )

export const useTasks = (onboardingId?: string) => {
  return useFetch<GetRequestInterface<OnboardingTaskInterface>>(
    onboardingId ? `${API.ONBOARDING}/employees/${onboardingId}/tasks` : null,
    'v2',
  )
}

export const cancelTask = (onboardingId: string, taskId: number, cancel_reason: string) =>
  apiWithoutHandling.post<OnboardingTaskInterface>(
    `${API.ONBOARDING}/employees/${onboardingId}/tasks/${taskId}/cancel`,
    { cancel_reason },
    undefined,
    'v2',
  )

export const completeTask = (onboardingId: string, taskId: number) =>
  apiWithoutHandling.post<OnboardingTaskInterface>(
    `${API.ONBOARDING}/employees/${onboardingId}/tasks/${taskId}/complete`,
    undefined,
    undefined,
    'v2',
  )

export const startTask = (onboardingId: string, taskId: number) =>
  apiWithoutHandling.post<OnboardingTaskInterface>(
    `${API.ONBOARDING}/employees/${onboardingId}/tasks/${taskId}/start`,
    undefined,
    undefined,
    'v2',
  )

export const reassignTask = (
  onboardingId: string,
  taskId: number,
  default_assignee: EmployeeOptionInterface,
) =>
  apiWithoutHandling.post<OnboardingTaskInterface>(
    `${API.ONBOARDING}/employees/${onboardingId}/tasks/${taskId}/reassign`,
    {
      default_assignee_type: {
        id: 'employee',
      },
      default_assignee,
    },
    undefined,
    'v2',
  )

export const employeeOnboardingTasksRequests: RequestInterfaceNew<OnboardingTaskInterface> =
  {
    get: async ({ onboardingId, id }) =>
      api.get(`${API.ONBOARDING}/employees/${onboardingId}/tasks/${id}`, undefined, 'v2'),
    update: async (data, { onboardingId, id }) => {
      return api.patch(
        `${API.ONBOARDING}/employees/${onboardingId}/tasks/${id}`,
        data,
        undefined,
        'v2',
      )
    },
    submit: async (data, { onboardingId }) => {
      return api.post(
        `${API.ONBOARDING}/employees/${onboardingId}/tasks`,
        data,
        undefined,
        'v2',
      )
    },
  }

export const sendSignatureRequest = (onboardingId: string, id: number) =>
  apiWithoutHandling.post(
    `${API.ONBOARDING}/employees/${onboardingId}/documentSignatureRequests/${id}/send`,
    undefined,
    undefined,
    'v2',
  )

export const deleteSignatureRequest = (onboardingId: string, id: number) =>
  apiWithoutHandling.delete(
    `${API.ONBOARDING}/employees/${onboardingId}/documentSignatureRequests/${id}`,
    undefined,
    'v2',
  )

export const getOnboardingTimelineV2CommentsAPI = (onboardingId: string) =>
  getCommentsAPI({
    baseUrl: `${API.ONBOARDING}/employees/${onboardingId}/comments`,
    apiVersion: 'v2',
  })
