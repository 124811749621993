import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Avatar,
  Banner,
  Box,
  HStack,
  IconButton,
  StatusPopup,
  TableWidget,
  Token,
  usePrevious,
  useStatusPopup,
} from '@revolut/ui-kit'
import { cloneDeep, get, isEqual } from 'lodash'
import { css } from 'styled-components'
import { QueryObserverResult } from 'react-query'

import { TableNames } from '@src/constants/table'
import { useTable, useTableReturnType } from '@src/components/Table/hooks'
import {
  applyImportSession,
  deleteImportSessionRow,
  editImportSessionRow,
  getUploadSessionTable,
  getUploadSessionTableStats,
  useImportSessionBulkItemSelector,
} from '@src/api/bulkDataImport'
import { deleteEditableTableRow, editEditableTableCell, getEditableTable } from './api'
import {
  ImportInterface,
  ImportSessionInterface,
  ImportSessionStatsInterface,
} from '@src/interfaces/bulkDataImport'
import {
  CellTypes,
  FilterByInterface,
  RowInterface,
  SortByInterface,
} from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { BulkDataImportActionsProps } from '@src/features/BulkDataImport/BulkDataImportActions'
import { arrayErrorsToFormError } from '@src/utils/form'
import EditableTable from '@src/components/Table/EditableTable/EditableTable'
import { ImportState } from '../BulkDataImport/ImportState'
import {
  GenericEditableTableOnBulkChange,
  GenericEditableTableOnChange,
} from './components'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
  SelectionControls,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import {
  CellState,
  stateAttr,
} from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'
import { StatsConfig, useSelectableTableStats } from '@src/components/StatFilters/hooks'
import { StatFilters } from '@src/components/StatFilters/StatFilters'
import { ApiVersion, GetRequestData, GetRequestInterface } from '@src/interfaces'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { AxiosPromise } from 'axios'

export const CellCss = css`
  &[data-${stateAttr}=${CellState.active}],
  &[data-${stateAttr}=${CellState.hovered}] {
    cursor: pointer;
    box-shadow: inset 0 0 0 1px ${Token.color.accent};

    > [data-cell-cover]:hover {
      box-shadow: inset 0 0 0 1px ${Token.color.accent};
    }
  }
`

export const CellErrorCss = css`
  background-color: ${Token.color.redActionBackground} !important;
  ${CellCss};
`

const CellWarningCss = css`
  background-color: ${Token.color.orange_10} !important;
  ${CellCss};
`

const ActionColCss = css`
  z-index: initial !important;
`

const actionColumnKey = 'action'
const stateColumnKey = 'state.id'

const statsConfigBeforeApply: StatsConfig<ImportSessionStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'errors',
    title: 'Errors',
    filterKey: 'False',
    color: Token.color.danger,
  },
  {
    key: 'warnings',
    title: 'Warnings',
    filterKey: 'False',
    customColumnName: 'warnings__isempty',
    color: Token.color.warning,
  },
  {
    key: 'success',
    title: 'Success',
    filterKey: 'True',
    color: Token.color.success,
  },
]

const statsConfigAfterApply: StatsConfig<ImportSessionStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'state_failure',
    title: 'Errors',
    filterKey: 'False',
    color: Token.color.danger,
  },
  {
    key: 'state_success',
    title: 'Success',
    filterKey: 'True',
    color: Token.color.success,
  },
]

const duplicateEmailValidationError = 'Email is duplicated on another item'

export type CommonGenericEditableTableEntity =
  | 'employee'
  | 'team'
  | 'role'
  | 'job'
  | 'candidate'
  | 'balance'
  | 'policy'
  | 'group'
  | 'goal'
  | 'document'
  | 'contract'

export interface CommonGenericEditableTableRowOptions {
  onChange: GenericEditableTableOnChange
  onBulkChange: GenericEditableTableOnBulkChange
  refreshTableState: () => void
}

export interface TableActionsProps<T> {
  sessionData?: ImportSessionInterface
  getSelectedItems: () => number[]
  getSelectedRowsData: () => ImportInterface<T>[]
  refreshTableState: () => void
  apiEndpoint: string
  selectionContext: SelectTableWrapperOnChangeData<{ id: number }> | undefined
}

interface DeleteConfirmationProps<T> {
  open: boolean
  data?: ImportInterface<T>
  onClose: () => void
  onSuccess: () => void
}

export interface CommonGenericEditableTableProps<T, S = ImportSessionStatsInterface> {
  apiEndpoint: string
  tableName: TableNames
  row: (options: CommonGenericEditableTableRowOptions) => RowInterface<ImportInterface<T>>
  tableActions?: (props: TableActionsProps<T>) => React.ReactNode
  tableInfo?: React.ReactNode
  entity: CommonGenericEditableTableEntity
  hiddenColumns?: Record<string, boolean>
  filterByInitial?: FilterByInterface[]
  sortByInitial?: SortByInterface[]
  deleteConfirmation?: (props: DeleteConfirmationProps<T>) => React.ReactNode
  isPreview?: boolean
  apiVersion?: ApiVersion
  ignoreQueryParams?: string[]
  onActionColumnEdit?: (id: number) => void
  onActionColumnPreview?: (rowData: ImportInterface<T>) => void
  getCustomEditCellAction?: (
    data: T,
    rowId: number,
    sessionId: number | undefined,
  ) => AxiosPromise<T> | undefined
  existingEntitiesDeleteHandler?: (rowId: number) => AxiosPromise<T>
  onChangeValidationState?: (state: 'valid' | 'invalid') => void
  onAfterRefresh?: () => void
  preselectedItems?: { id: number }[]
  selectAllItems?: boolean
  emptyState?: React.ReactNode
  table?: useTableReturnType<ImportInterface<T>, S>
  refreshTableHandler?: (cb: () => void) => void
}

interface ExistingEntitiesActionsParams {
  getSelectedItems: () => number[]
  refreshTableState: () => void
  someSelected: boolean
}

/** Use when working with real entity APIs, e.g. GET `/employees`; PATCH/DELETE `/employees/{id}` */
export interface GenericExistingEntitiesTableProps<T, S>
  extends CommonGenericEditableTableProps<T, S> {
  variant: 'existingEntities'
  tableDataTransform?: (
    data: GetRequestData<T>,
  ) => GetRequestInterface<ImportInterface<T>>
  actions?: (params: ExistingEntitiesActionsParams) => React.ReactNode
}

export interface GenericTemporaryEntitiesTableProps<T>
  extends CommonGenericEditableTableProps<T, ImportSessionStatsInterface> {
  actions?: (params: BulkDataImportActionsProps) => React.ReactNode
  sessionData: ImportSessionInterface
  refetchSessionData: () => Promise<QueryObserverResult<ImportSessionInterface, Error>>
  disabled?: boolean
  variant: 'temporaryEntities'
}

/** Use when working with temporary entities in bulk standard import flows, e.g. GET `/employeeUploads/{sessionId}/items`; PATCH/DELETE `/employeeUploads/{sessionId}/items/{itemId}` */
export type GenericEditableTableProps<T, S> =
  | GenericExistingEntitiesTableProps<T, S>
  | GenericTemporaryEntitiesTableProps<T>

// removing undefined properties. On table change we check if error object is empty due to performance reasons, that's why we cannot have undefined properties
const getErrors = <T,>(
  prevErrors: Partial<Record<keyof T, string[]>>,
  newErrors: Record<string, undefined | string[]>,
) => {
  const errorsObject = { ...prevErrors, ...newErrors }
  return Object.keys(errorsObject).reduce<Partial<Record<keyof T, string[]>>>(
    (acc, key) => {
      if (errorsObject[key]) {
        acc[key as keyof T] = errorsObject[key]
      }
      return acc
    },
    {},
  )
}

export const GenericEditableTable = <T, S extends ImportSessionStatsInterface>({
  apiEndpoint,
  tableName,
  row,
  tableActions,
  tableInfo,
  entity,
  hiddenColumns,
  filterByInitial,
  sortByInitial,
  deleteConfirmation,
  isPreview,
  apiVersion,
  preselectedItems,
  ignoreQueryParams,
  onActionColumnEdit,
  onActionColumnPreview,
  getCustomEditCellAction,
  existingEntitiesDeleteHandler = rowId =>
    deleteEditableTableRow(apiEndpoint, rowId, apiVersion),
  refreshTableHandler = () => {},
  onChangeValidationState,
  selectAllItems,
  onAfterRefresh,
  table: tableFromProps,
  ...props
}: GenericEditableTableProps<T, S>) => {
  const statusPopup = useStatusPopup()

  const [deleteRowState, setDeleteRowState] = useState<{
    open: boolean
    data?: ImportInterface<T>
  }>({
    open: false,
  })
  const [deletePending, setDeletePending] = useState(false)
  const [submitPending, setSubmitPending] = useState(false)
  const [state, setState] = useState(
    props.variant === 'temporaryEntities' ? props.sessionData.state.id : undefined,
  )
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<ImportInterface<T>>>()

  const defaultTable = useTable(
    {
      getItems:
        props.variant === 'temporaryEntities'
          ? getUploadSessionTable<T>(apiEndpoint, props.sessionData.id, apiVersion)
          : getEditableTable<T>(apiEndpoint, apiVersion, props.tableDataTransform),
      getStats:
        props.variant === 'temporaryEntities'
          ? getUploadSessionTableStats(apiEndpoint, props.sessionData.id, apiVersion)
          : undefined,
    },
    filterByInitial,
    sortByInitial,
    { omitKeys: ignoreQueryParams, disable: !!tableFromProps },
  )
  const table = tableFromProps || defaultTable

  useEffect(() => {
    if (table.refresh) {
      refreshTableHandler(table.refresh)
    }
  }, [table.refresh])

  const previousErrors = usePrevious(table.stats?.errors)

  const tableFilters = useMemo(
    () => filterSortPageIntoQuery(table.sortBy, table.filterBy) as Record<string, string>,
    [table.filterBy, table.sortBy],
  )

  const selectTableControls = useRef<SelectionControls<ImportInterface<T>>>()

  const bulkItemOptions = useImportSessionBulkItemSelector(
    apiEndpoint,
    props.variant === 'temporaryEntities' ? props.sessionData?.id : null,
    tableFilters,
  )

  const isBeforeApply =
    props.variant === 'temporaryEntities' &&
    (props.sessionData.state.id === 'pending' ||
      props.sessionData.state.id === 'ready_for_review')

  const sessionDataStateId =
    props.variant === 'temporaryEntities' && props.sessionData.state.id

  const statFiltersProps = useSelectableTableStats<
    ImportInterface<T>,
    ImportSessionStatsInterface
  >({
    table,
    statsConfig: isBeforeApply ? statsConfigBeforeApply : statsConfigAfterApply,
    columnName: isBeforeApply ? 'errors__isempty' : 'error_message__isnull',
    totalKey: 'total',
  })

  const produceTableData = (
    id: string | number,
    cb: (rowData: ImportInterface<T>) => ImportInterface<T>,
  ) => {
    table.setData(prev => prev.map(r => (r.id === id ? cloneDeep(cb(r)) : r)))
  }

  useEffect(() => {
    if (props.variant === 'existingEntities' || !sessionDataStateId) {
      return undefined
    }

    if (sessionDataStateId !== 'applying') {
      if (state !== sessionDataStateId) {
        table.refresh()
      }
      setState(sessionDataStateId)

      return undefined
    }

    const interval = setInterval(() => {
      table.refresh()
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [props.variant, sessionDataStateId, state, table.filterBy])

  useEffect(() => {
    /** If they fixed all the errors, we should clear the filters and show all the rows */
    if (previousErrors && !table.stats?.errors) {
      table.resetFiltersAndSorting()
    }
  }, [table.stats?.errors, previousErrors])

  useEffect(() => {
    if (preselectedItems) {
      const rowsToBePreselected = table.data.filter(r =>
        preselectedItems.find(i => i.id === r.id),
      )
      selectTableControls.current?.setSelection(rowsToBePreselected)
    }
  }, [table.data, preselectedItems])

  useEffect(() => {
    if (onChangeValidationState) {
      const hasErrors = table.data.some(item => Object.keys(item.errors).length)

      onChangeValidationState(hasErrors ? 'invalid' : 'valid')
    }
  }, [table.data, onChangeValidationState])

  const onEditAction = (rowData: ImportInterface<T>, values: Record<string, unknown>) => {
    const containsDuplicateEmailError =
      'email' in values &&
      table.data.filter(r => {
        const emailErrors = get(r.errors, 'email') as unknown
        return (
          Array.isArray(emailErrors) &&
          emailErrors.some(e => e === duplicateEmailValidationError)
        )
      }).length > 0
    const initLoadingFields = Object.keys(values).reduce<Record<string, boolean>>(
      (acc, key) => {
        acc[key] = true
        return acc
      },
      {},
    )
    const initErrorFields = Object.keys(values).reduce<Record<string, undefined>>(
      (acc, key) => {
        acc[key] = undefined
        return acc
      },
      {},
    )

    produceTableData(rowData.id, prev => {
      return {
        ...prev,
        data: { ...prev.data, ...values },
        loading: { ...prev.loading, ...initLoadingFields },
        errors: getErrors<T>(prev.errors, initErrorFields),
      }
    })

    const editAction =
      getCustomEditCellAction?.(
        {
          ...rowData.data,
          ...values,
        },
        rowData.id,
        props.variant === 'temporaryEntities' ? props.sessionData.id : undefined,
      ) ||
      (props.variant === 'temporaryEntities'
        ? editImportSessionRow(
            apiEndpoint,
            props.sessionData.id,
            rowData.id,
            {
              data: { ...rowData.data, ...values },
            },
            apiVersion,
          )
        : editEditableTableCell(apiEndpoint, rowData.id, { ...values }, apiVersion))

    editAction
      .then(response => {
        onAfterRefresh?.()
        table.refreshStats()
        /** Need to refresh the table, because in duplicate email cases, this change may change validation errors on other rows in the table */
        if (containsDuplicateEmailError) {
          table.refresh()
        }
        produceTableData(rowData.id, prev => {
          if (props.variant === 'temporaryEntities') {
            const loadingFields = Object.keys(values).reduce<Record<string, boolean>>(
              (acc, key) => {
                acc[key] = false
                return acc
              },
              {},
            )

            return {
              ...prev,
              ...response.data,
              loading: { ...prev.loading, ...loadingFields },
            }
          }
          if (props.variant === 'existingEntities') {
            const dataFields = Object.keys(values).reduce<Record<string, unknown>>(
              (acc, key) => {
                acc[key] = get(response.data, key)
                return acc
              },
              {},
            )
            const loadingFields = Object.keys(values).reduce<Record<string, boolean>>(
              (acc, key) => {
                acc[key] = false
                return acc
              },
              {},
            )
            const errorFields = Object.keys(values).reduce<Record<string, undefined>>(
              (acc, key) => {
                acc[key] = undefined
                return acc
              },
              {},
            )

            return {
              ...prev,
              data: { ...prev.data, ...dataFields },
              loading: { ...prev.loading, ...loadingFields },
              errors: getErrors<T>(prev.errors, errorFields),
            }
          }
          throw new Error('not reachable')
        })
      })
      .catch(error => {
        const fieldsError = Object.keys(values).reduce<Record<string, [string]>>(
          (acc, field) => {
            if (props.variant === 'temporaryEntities') {
              const apiError: unknown = get(error, `response.data.${field}`)
              const fieldError =
                typeof apiError === 'string' ? apiError : 'Something went wrong'
              acc[field] = [fieldError]
              return acc
            }
            if (props.variant === 'existingEntities') {
              const errors = arrayErrorsToFormError(error?.response?.data)
              const apiError: unknown = get(errors, field)
              const apiNonFieldError: unknown = get(errors, 'non_field_errors')
              const detailError: unknown = get(errors, 'detail')

              let fieldError: string

              if (typeof apiError === 'string') {
                fieldError = apiError
              } else if (
                typeof apiError === 'object' &&
                apiError !== null &&
                'non_field_errors' in apiError &&
                typeof apiError.non_field_errors === 'string'
              ) {
                fieldError = apiError.non_field_errors
              } else if (typeof apiNonFieldError === 'string') {
                fieldError = apiNonFieldError
              } else if (typeof detailError === 'string') {
                fieldError = detailError
              } else {
                fieldError = 'Something went wrong'
              }

              acc[field] = [fieldError]
              return acc
            }
            throw new Error('not reachable')
          },
          {},
        )

        const loadingFields = Object.keys(values).reduce<Record<string, boolean>>(
          (acc, key) => {
            acc[key] = false
            return acc
          },
          {},
        )

        produceTableData(rowData.id, prev => ({
          ...prev,
          data: { ...prev.data, ...values },
          errors: getErrors<T>(prev.errors, fieldsError),
          loading: { ...prev.loading, ...loadingFields },
        }))
      })
  }

  const onBulkFieldsChange: GenericEditableTableOnBulkChange = (rowId, values) => {
    const rowData = table.data.find(r => r.id === rowId)

    if (rowData) {
      onEditAction(rowData, values)
    }
  }

  const onFieldChange: GenericEditableTableOnChange = (rowId, value, field) => {
    const rowData = table.data.find(r => r.id === rowId)

    if (rowData && !isEqual(get(rowData.data, field), value)) {
      onEditAction(rowData, { [field]: value })
    }
  }

  const refreshTableState = () => {
    if (props.variant === 'temporaryEntities') {
      bulkItemOptions.refetch()
    }
    selectTableControls.current?.resetState()
    table.refresh()
    onAfterRefresh?.()
  }

  const tableRow = useMemo(() => {
    const originalRow = row({
      onChange: onFieldChange,
      onBulkChange: onBulkFieldsChange,
      refreshTableState,
    })
    const actionableCells = isPreview
      ? originalRow.cells
      : originalRow.cells.map(c => ({
          ...c,
          ...(props.variant === 'temporaryEntities'
            ? { filterKey: null, sortKey: null } // BE doesn't support filtering & sorting in the bulk session mode
            : null),
          wrapperCss: (rowData: ImportInterface<T>) => {
            const idPoints = c.idPoint.split(',')
            return idPoints.find(id => get(rowData.errors, id))
              ? CellErrorCss
              : idPoints.find(id => get(rowData.warnings, id))
              ? CellWarningCss
              : CellCss
          },
        }))

    return {
      ...originalRow,
      cells: [
        {
          ...getSelectCellConfig(undefined, 55),
        },
        ...actionableCells,
        {
          type: CellTypes.insert,
          idPoint: actionColumnKey,
          dataPoint: 'action',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: 'Actions',
          width: 100,
          insert: ({ data }: { data: ImportInterface<T> }) => {
            return (
              <HStack gap="s-16">
                {onActionColumnPreview && (
                  <IconButton
                    color={Token.color.greyTone50}
                    onClick={() => onActionColumnPreview(data)}
                    size={16}
                    useIcon="EyeShow"
                    data-testid={`preview-${data.id}`}
                  />
                )}
                {onActionColumnEdit && (
                  <IconButton
                    color={Token.color.greyTone50}
                    onClick={() => onActionColumnEdit(data.id)}
                    size={16}
                    useIcon="Pencil"
                  />
                )}
                <IconButton
                  useIcon="Delete"
                  color={Token.color.greyTone50}
                  size={16}
                  onClick={() => setDeleteRowState({ open: true, data })}
                  aria-label={`Delete ${entity}`}
                />
              </HStack>
            )
          },
          wrapperCss: () => ActionColCss,
        },
        {
          type: CellTypes.insert,
          idPoint: stateColumnKey,
          dataPoint: 'state.name',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: 'State',
          insert: ({ data }: { data: ImportInterface<T> }) => <ImportState data={data} />,
          width: 150,
        },
      ].filter(Boolean),
    }
  }, [row, onFieldChange, onBulkFieldsChange, table.data])

  const onSubmit = async () => {
    if (props.variant === 'existingEntities') {
      return
    }

    setSubmitPending(true)

    try {
      await applyImportSession(apiEndpoint, props.sessionData.id)
      await props.refetchSessionData()

      if (
        props.sessionData.state.id === 'pending' ||
        props.sessionData.state.id === 'ready_for_review'
      ) {
        table.resetFiltersAndSorting()
      }
    } finally {
      setSubmitPending(false)
    }
  }

  const onDeleteConfirm = () => {
    if (deleteRowState.data) {
      setDeletePending(true)

      const deleteAction =
        props.variant === 'temporaryEntities'
          ? deleteImportSessionRow(apiEndpoint, apiVersion)(
              props.sessionData.id,
              deleteRowState.data.id,
            )
          : existingEntitiesDeleteHandler(deleteRowState.data.id)

      deleteAction
        .then(() => {
          if (deleteRowState.data) {
            selectTableControls.current?.onSelect(
              `${deleteRowState.data.id}`,
              false,
              deleteRowState.data,
            )
          }
          table.refresh()
          setDeleteRowState({ open: false })
        })
        .catch(error => {
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>Failed to delete</StatusPopup.Title>
              <StatusPopup.Description>
                {getStringMessageFromError(error)}
              </StatusPopup.Description>
            </StatusPopup>,
          )
        })
        .finally(() => {
          setDeletePending(false)
        })
    }
  }

  const getSelectedItems = () => {
    if (selectedData?.selectedRowsData.length) {
      return selectedData.selectedRowsData.map(r => r.id)
    }

    if (selectedData?.isAllSelected && props.variant === 'temporaryEntities') {
      return bulkItemOptions.options?.filter(
        r => !selectedData.excludeListIds.has(`${r}`),
      )
    }

    if (selectedData?.isAllSelected && props.variant === 'existingEntities') {
      return table.data
        ?.map(r => r.id)
        .filter(r => !selectedData.excludeListIds.has(`${r}`))
    }

    return []
  }

  const getSelectedRowsData = () => {
    if (selectedData?.isAllSelected) {
      return table.data?.filter(r => !selectedData.excludeListIds.has(`${r.id}`))
    }

    return selectedData?.selectedRowsData || []
  }

  return (
    <>
      {props.variant === 'temporaryEntities' &&
      (props.sessionData?.source === 'google' ||
        props.sessionData?.source === 'slack') ? (
        <Box mb="s-8">
          <Banner>
            <Banner.Avatar>
              <Avatar useIcon="ExclamationTriangle" />
            </Banner.Avatar>
            <Banner.Content>
              <Banner.Title>
                Please review the information in the table. Some information was imported
                with default values only
              </Banner.Title>
            </Banner.Content>
          </Banner>
        </Box>
      ) : null}

      <TableWidget>
        {props.variant === 'temporaryEntities' ? (
          <TableWidget.Info>
            <StatFilters {...statFiltersProps} />
          </TableWidget.Info>
        ) : tableInfo ? (
          <TableWidget.Info>{tableInfo}</TableWidget.Info>
        ) : null}
        {tableActions ? (
          <TableWidget.Actions>
            {tableActions({
              sessionData:
                props.variant === 'temporaryEntities' ? props.sessionData : undefined,
              getSelectedItems,
              getSelectedRowsData,
              refreshTableState,
              apiEndpoint,
              selectionContext: selectedData,
            })}
          </TableWidget.Actions>
        ) : null}
        <TableWidget.Table>
          <SelectTableWrapper<ImportInterface<T>>
            enabled={!isPreview}
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
            onControlsLoaded={controls => {
              selectTableControls.current = controls
            }}
            selectAll={selectAllItems}
          >
            <EditableTable
              name={tableName}
              {...table}
              useWindowScroll
              onChange={() => {}}
              enableSettings={false}
              lockFirstColumn={isPreview}
              lockLastColumn={!isPreview}
              rowHeight={isPreview ? 'small' : 'medium'}
              row={tableRow}
              emptyState={props.emptyState}
              hiddenCells={{
                select: isPreview,
                [actionColumnKey]:
                  isPreview ||
                  (props.variant === 'temporaryEntities' ? props.disabled : false),
                [stateColumnKey]:
                  isPreview ||
                  (props.variant === 'temporaryEntities' ? !props.disabled : true),
                ...hiddenColumns,
              }}
            />
          </SelectTableWrapper>
        </TableWidget.Table>
      </TableWidget>

      {props.variant === 'temporaryEntities'
        ? props.actions?.({
            onSubmit,
            submitPending,
            sessionData: props.sessionData,
            disableActions: !table.stats || table.stats.errors > 0,
            empty: !table.data.length,
          })
        : props.actions?.({
            getSelectedItems,
            refreshTableState,
            someSelected: !!selectedData?.someSelected,
          }) || null}

      {deleteConfirmation?.({
        ...deleteRowState,
        onClose: () => setDeleteRowState({ open: false }),
        onSuccess: refreshTableState,
      }) || (
        <ConfirmationDialog
          open={deleteRowState.open}
          onClose={() => setDeleteRowState({ open: false })}
          onConfirm={onDeleteConfirm}
          loading={deletePending}
          onReject={() => setDeleteRowState({ open: false })}
          yesMessage="Confirm"
          noMessage="Cancel"
          body={`Are you sure you want to delete this ${entity}?`}
        />
      )}
    </>
  )
}
